<template>
    <div>
        <Modal
            :show="props.show"
            :title="`Export ${ title } report`"
            @update:show="cancel"
        >
            <template v-slot:body>
                <template v-if="reportExport.status === 'draft'">
                    <div>
                        <FormSwitch v-if="canSaveReport" v-model="isReportVisible" label="Save report?"></FormSwitch>
                        <FormSwitch v-if="canShareReport && isReportVisible" v-model="isReportPublic" label="Share report across organisation"></FormSwitch>
                    </div>
                    <template v-if="isReportVisible">
                        <div class="row mt-1">
                            <div class="col-12">
                                <label class="form-label">Report title</label>
                                <input
                                    type="text"
                                    v-model="reportExport.name"
                                    class="form-control border border-dark border-opacity-25 rounded"
                                />
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-12">
                                <label class="form-label">Select folder</label>
                                <VueMultiselect
                                    v-model="reportExport.folder"
                                    :options="folders"
                                    :taggable="true"
                                    @tag="createFolder"
                                    placeholder="Select folder"
                                    label="name"
                                    track-by="uuid"
                                    id="exportFolders"
                                ></VueMultiselect>
                            </div>
                        </div>
                    </template>
                </template>

                <template v-if="reportExport.status === 'pending' || reportExport.status === 'queued'">
                    <div class="row">
                        <div class="col-12">
                            <label class="form-label">Preparing report</label>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped bg-warning progress-bar-animated"
                                     role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                     style="width: 100%"></div>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="reportExport.status === 'in-progress'">
                    <div class="row">
                        <div class="col-12">
                            <label class="form-label d-flex justify-content-between"><div>Exporting report</div> <div class="text-black-50">{{ estimated.toHuman({listStyle: "long"}) }} left</div></label>
                            <div class="progress">
                                <div class="progress-bar progress-bar-animated" role="progressbar"
                                     :aria-valuenow="reportExport.progress" aria-valuemin="0" aria-valuemax="100"
                                     :style="`width: ${reportExport.progress}%`"></div>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="reportExport.status === 'completed'">
                    <div class="row">
                        <div class="col-12">
                            <div class="alert alert-success" role="alert">
                                Export complete.
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="reportExport.status === 'failed'">
                    <div class="row">
                        <div class="col-12">
                            <label class="form-label">Report failed</label>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped bg-danger progress-bar-animated"
                                     role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                     style="width: 100%"></div>
                            </div>
                        </div>
                    </div>
                </template>

            </template>
            <template v-slot:footer>
                <template v-if="reportExport.status === 'draft'">
                    <Button @click="cancel" class="btn btn-secondary mx-1">Cancel</Button>
                    <Button @click="start" class="btn btn-primary">Export</Button>
                </template>
                <template v-if="reportExport.status === 'pending' || reportExport.status === 'queued'">
                    <Button @click="cancel" class="btn btn-secondary mx-1">Cancel</Button>
                    <Button @click="notify" class="btn btn-primary" disabled>
                        <span class="mdi mdi-email me-1"></span>Email me
                    </Button>
                </template>
                <template v-if="reportExport.status === 'in-progress'">
                    <Button @click="cancel" class="btn btn-secondary mx-1">Cancel</Button>
                    <Button v-if="notificationRequested" class="btn btn-success" disabled="disabled">
                        <span class="mdi mdi-check-circle me-1"></span>Email me
                    </Button>
                    <Button v-else @click="notify" class="btn btn-primary">
                        <span class="mdi mdi-email me-1"></span>Email me
                    </Button>
                </template>
                <template v-if="reportExport.status === 'completed'">
                    <Button @click="cancel" class="btn btn-secondary mx-1">Cancel</Button>
                    <Button @click="download" class="btn btn-primary">
                        <span class="mdi mdi-download me-1"></span>Download
                    </Button>
                </template>
            </template>
        </Modal>
    </div>
</template>
<style scoped>

</style>

<script setup lang="ts">

import Button from "@components/Button.vue";
import FormSwitch from "@components/Forms/FormSwitch.vue";
import Modal from "@components/Modal.vue";
import {ToastInterface} from "@components/toast";
import axios from "axios";
import {Duration} from "luxon";
import {computed, inject, onMounted, Ref, ref, watch} from "vue";
import VueMultiselect from "vue-multiselect";

let timer: number;
const toast: ToastInterface = inject('toast')!;
const notificationRequested: Ref<boolean> = ref(false);

const props = withDefaults(
    defineProps<{
        year?: string | number,
        month?: string,
        quarter?: string,
        fundingTypes?: string[],
        fundingType?: string,
        path: string,
        show: boolean,
        model?: string,
        module: string,
        fields?: string[],
        rule_sets?: { "type": string, "rules": { "field": string, "operator": string, "value": string }[][] }[],
        rule_sets_operator?: string,
    }>(),
    {}
)

const emit = defineEmits<{
    (e: 'update:show', value: boolean): void,
}>();

const folders = ref<App.Resource.ExportFolderResource[]>([]);
const isReportVisible: Ref<boolean> = ref<boolean>(false);
const isReportPublic: Ref<boolean> = ref<boolean>(false);

const reportExport = ref<App.Resource.ExportResource>({
    name: `${props.model} report`,
    model: props.model ?? '',
    status: "draft",
    notify: false,
    type: 'hidden',
    has_validation_errors: false
});


const cancel = () => {
    window.clearInterval(timer);
    emit('update:show', false);
}

const start = () => {
    axios
        .post(
            props.path,
            {
                year: props.year,
                month: props.month,
                qtr: props.quarter,
                fundingType: props.fundingType,
                fundingTypes: props.fundingTypes?.map((fundingType) => fundingType.value),
                model: props.model,
                module: props.module,
                fields: props.fields?.filter(n => n),
                rule_sets: props.rule_sets?.filter(n => n?.type),
                rule_sets_operator: props.rule_sets_operator,
                name: reportExport.value.name,
                download: true,
                folder: {
                    name: reportExport.value.folder?.name,
                    uuid: reportExport.value.folder?.uuid
                },
                type: isReportVisible.value ? (isReportPublic.value ? 'public' : 'private') : 'hidden',
            }
        )
        .then(response => {
            reportExport.value = response.data.data;
            timer = window.setInterval(() => progress(), 3000);
        })
        .catch(error => {
            toast.error(error.response.data.message);
            window.clearInterval(timer);
        })
        .finally(() => {
            getFolders();
        })
}

const progress = () => {
    axios
        .get(
            route('api.exports.show', {export: reportExport.value})
        )
        .then(response => {
                reportExport.value = response.data.data;
            }
        )
}

const notify = () => {
    axios
        .patch(
            route('api.exports.update', {export: reportExport.value}),
            {notify: true,}
        )
        .then(response => {
            reportExport.value = response.data.data;
            notificationRequested.value = true;
        })
        .catch(error => {
            toast.error(error.response.data.message)
        })
}

const download = () => {
    window.location.href = route('api.exports.download', {export: reportExport.value});
}

const getFolders = () => {
    axios
        .get(route('api.folders.index'), {params: {module: props.module,},})
        .then((response) => {
            folders.value = response.data.data
        })
        .catch(error => {
            toast.error(error.response.data.message)
        })
};

const createFolder = (name: string) => {
    reportExport.value.folder = {
        name: name
    }
};

onMounted(() => {
    getFolders();
});

const title = computed(() => {
    let _name = 'Untitled';
    switch (props.module) {
        case 'hacc_mds':
            _name = `${props.fundingType} ${props.quarter}/${props.year}`
            break;
        case 'tfnsw':
            _name = `${props.month} ${props.year}`
            break;
        default:
            _name = props.model ?? 'Untitled';
            break;
    }
    reportExport.value.name = _name;
    return _name;
});

const estimated = computed(() => {
    return Duration.fromMillis(reportExport.value.estimated * 1000).rescale()
})

const canSaveReport = computed(() => window.permissions.indexOf('save_report'));
const canShareReport = computed(() => window.permissions.indexOf('share_report'));

watch(reportExport, (reportExport) => {
    if (reportExport.status === 'completed' || reportExport.status === 'failed') {
        clearInterval(timer);
    }
})

watch(props, (props) => {
    reportExport.value.name = `${title.value} report`;
    reportExport.value.model = props.model ?? '';
    reportExport.value.status = "draft";
    reportExport.value.notify = false;
})

</script>